import {
  BaseMenuItem, Image, LayoutConfigurationItem, RelatedMenuItem, Seo,
} from '@app/types/configurationTypes';
import { GoogleAdsProps, GoogleMaps, GAEvent } from '@app/types/googleTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { RequestContentType } from '@app/services/kentico/types/requestTypes';
import { ArticleModularContentItem, NewsList } from '@app/types/newsTypes';
import { Question } from './faqQuestionTypes';
import { PageError } from './errorTypes';

export enum WebTemplateTypes {
  WebGoogleMaps = 'web_google_maps',
  WebGoogleAds = 'web_ad_manager_banner',
  WebDepartment = 'web_department',
  WebPerson = 'web_person',
  WebPartner = 'web_partner',
  WebLanding = 'web_landing',
  WebCard = 'web_card',
  WebCardRosterArchive = 'web_card_roster_archive',
  WebCtaButton = 'cta_button',
  WebCtaRosterArchiveButton = 'web_cta_roster_archive_button',
  WebCtaImage = 'web_cta_image',
  WebCtaDuoButton = 'web_cta_duo',
  WebPageImage = 'web_page_image',
  WebPageTitle = 'web_page_title',
  WebPageVideo = 'web_page_video',
  WebPageSeparator = 'web_page_separator',
  WebTabs = 'web_tabs',
  WebTabItem = 'web_tab_item',
  WebEditorial = 'web_editorial',
  WebEditorialDuo = 'web_editorial_duo',
  WebEmbedded = 'embedded',
  WebEmbeddedOneTrust = 'embedded_onetrust',
  WebEmbeddedOverlay = 'embedded_overlay',
  WebTicketsPartners = 'web_tickets_partners',
  WebPartnerBottom = 'web_partner_bottom',
  WebShare = 'web_share',
  WebFaqCategoryGroup = 'web_faq_category_group',
  WebFaqCategory = 'web_faq_category',
  WebContentGroup = 'web_content_group',
  WebHomeLanding = 'web_home_landing',
  WebHomeSlider = 'web_home_slider',
}

export interface WebTemplateElement {
  id: string;
  type: WebTemplateTypes;
  showDivider?: boolean;
}

export interface WebGoogleMaps extends WebTemplateElement {
  type: WebTemplateTypes.WebGoogleMaps;
  map: GoogleMaps;
}

export interface WebPerson extends WebTemplateElement {
  type: WebTemplateTypes.WebPerson;
  firstName: string;
  lastName: string;
  position: string;
  quote: string;
  photo: Image;
  resume: string;
  url: string;
}

export enum WebPartnerAdditionalSectionsType {
  Header = 'header',
  Bottom = 'bottom',
}

export enum WebPartnerType {
  Cms = 'cms',
  GoogleAds = 'google',
}

export interface WebPartner extends WebTemplateElement {
  type: WebTemplateTypes.WebPartner;
  title: string;
  description: string;
  photo: Image;
  redirectUrl: string;
  adsSlotId?: string;
  geo: {
    countries?: string[];
    exclude?: boolean;
  };
  additionalSections: WebPartnerAdditionalSectionsType[];
  partnerType: WebPartnerType;
  googleAds: WebGoogleAds | null;
}

type WebDepartmentContent = WebPerson | WebPartner;

export interface WebDepartment extends WebTemplateElement {
  type: WebTemplateTypes.WebDepartment;
  title?: string;
  name?: string;
  showDivider?: boolean;
  content: WebTemplateData[];
}

export interface WebLanding extends WebTemplateElement {
  type: WebTemplateTypes.WebLanding;
  name?: string;
  isVisible?: boolean;
  content: WebTemplateData[];
  url: string;
}

export enum WebSliderLayoutSize {
  Medium = 'Medium',
  Big = 'Big',
}

export enum WebCardLayoutSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum WebCardLayoutType {
  Plain = 'Plain',
  Overlay = 'Overlay',
}

export interface WebGoogleAds extends WebTemplateElement {
  type: WebTemplateTypes.WebGoogleAds;
  ads: GoogleAdsProps;
}

export type MenuItem = BaseMenuItem | RelatedMenuItem | null;

export interface WebCardOptions {
  layoutSize?: WebCardLayoutSize;
  layoutType?: WebCardLayoutType;
}

export interface WebCard extends WebTemplateElement {
  type: WebTemplateTypes.WebCard;
  title?: string;
  subTitle?: string;
  layoutSize?: WebCardLayoutSize;
  layoutType?: WebCardLayoutType;
  backgroundImage: Image;
  link: MenuItem;
  cta: WebCtaButton[];
  analytic: GAEvent | null;
}

export interface WebCardRosterArchive extends WebTemplateElement {
  type: WebTemplateTypes.WebCardRosterArchive;
  title?: string;
  subTitle?: string;
  layoutSize?: WebCardLayoutSize;
  layoutType?: WebCardLayoutType;
  backgroundImage: Image;
  link: MenuItem;
  cta: WebCtaButton[];
  analytic: GAEvent | null;
}


export enum WebPageImageSizeType {
  Big = 'big',
  Medium = 'medium',
}

export interface WebPageImage extends WebTemplateElement {
  type: WebTemplateTypes.WebPageImage;
  image: Image;
  size: WebPageImageSizeType;
}

export interface WebPageTitle extends WebTemplateElement {
  type: WebTemplateTypes.WebPageTitle;
  title: string;
  subTitle: string;
}

export enum WebPageSeparatorSizeType {
  Full = 'full',
  Half = 'half',
}

export interface WebPageSeparator extends WebTemplateElement {
  type: WebTemplateTypes.WebPageSeparator;
  size: WebPageSeparatorSizeType;
}

export enum WebPageVideoSizeType {
  Full = 'big',
  Half = 'medium',
}

export interface WebPageVideo extends WebTemplateElement {
  type: WebTemplateTypes.WebPageVideo;
  size: WebPageVideoSizeType;
  videoId: string;
}

export interface WebEditorial extends WebTemplateElement {
  type: WebTemplateTypes.WebEditorial;
  content: ArticleModularContentItem[];
}

export interface WebEditorialDuo extends WebTemplateElement {
  type: WebTemplateTypes.WebEditorialDuo;
  isContentFirst: boolean;
  content: string;
  image: Image;
}

export interface WebEmbedded extends WebTemplateElement {
  type: WebTemplateTypes.WebEmbedded;
  content: string;
}

export interface WebEmbeddedOneTrust extends WebTemplateElement {
  type: WebTemplateTypes.WebEmbeddedOneTrust;
  content: string;
}

export interface WebEmbeddedOverlay extends WebTemplateElement {
  type: WebTemplateTypes.WebEmbeddedOverlay;
  content: string;
  delay: number;
}

export interface WebTabItem extends WebTemplateElement, Seo {
  type: WebTemplateTypes.WebTabItem;
  name: string;
  isVisible: boolean;
  url: string;
  content: WebTemplateData[];
}

export interface WebTabs extends WebTemplateElement {
  type: WebTemplateTypes.WebTabs;
  tabs: WebTabItem[];
}

export interface WebPartnerBottom extends WebTemplateElement {
  type: WebTemplateTypes.WebPartnerBottom;
  title: string;
  content: WebDepartment[];
}

export enum WebCtaButtonTemplateType {
  Plain = 'plain',
  Transparent = 'transparent'
}

export interface WebCtaButton extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaButton;
  label: string;
  template: WebCtaButtonTemplateType;
  menuItem: MenuItem;
  analytic: GAEvent | null;
}

export interface WebCtaImage extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaImage;
  image: Image;
  menuItem: MenuItem;
  analytic: GAEvent | null;
}

export interface WebCtaDuoButton extends WebTemplateElement {
  type: WebTemplateTypes.WebCtaDuoButton;
  content: WebCtaImage[];
}

export interface TicketsPartner {
  url: string;
  logo: Image;
}

export interface WebTicketsPartners extends WebTemplateElement {
  type: WebTemplateTypes.WebTicketsPartners;
  partner1: TicketsPartner;
  partner2: TicketsPartner;
  partner3: TicketsPartner;
  partner4: TicketsPartner;
}

export interface WebShare extends WebTemplateElement {
  type: WebTemplateTypes.WebShare;
}

export interface WebFaqGroup extends WebTemplateElement {
  type: WebTemplateTypes.WebFaqCategoryGroup;
  content: WebTemplateData[];
}

export interface WebFaqCategory extends WebTemplateElement {
  type: WebTemplateTypes.WebFaqCategory;
  categoryName: string;
  questions: Question[];
}
export interface WebContentGroup extends WebTemplateElement {
  type: WebTemplateTypes.WebContentGroup;
}

export interface WebHomeLanding extends WebTemplateElement {
  type: WebTemplateTypes.WebHomeLanding;
  title: string;
  layoutSize: WebCardLayoutSize;
  content: WebCard[];
}

export interface WebHomeSlider extends WebTemplateElement {
  type: WebTemplateTypes.WebHomeSlider;
  title: string;
  layoutSize: WebSliderLayoutSize;
  content: WebCard[];
}

export type WebTemplateData = WebDepartment | WebDepartmentContent | WebPerson | WebPartner | WebLanding
  | WebCard | WebCtaButton | WebPageImage | WebPageTitle | WebTabs | WebTabItem | WebEditorial | WebTicketsPartners
  | WebPartnerBottom | WebShare | WebFaqGroup | WebFaqCategory | WebCtaImage | WebCtaDuoButton | WebEditorialDuo
  | WebEmbedded | WebEmbeddedOverlay | WebContentGroup | WebGoogleAds | WebGoogleMaps | WebHomeLanding | WebHomeSlider
  | WebPageSeparator | WebPageVideo | WebEmbeddedOneTrust | WebCardRosterArchive | null;

export type LocalizedWebTemplateData = {
  [key in LanguageType]?: WebTemplateData[];
}

export type LocalizedWebTemplateError = {
  [key in LanguageType]?: PageError | null;
}

export interface NewsConfiguration {
  id: string;
  layoutConfiguration: LayoutConfigurationItem;
  tags?: string[];
  categories?: string[];
  type?: RequestContentType[];
}

export type News = NewsConfiguration & NewsList & {
  isLoadingData: boolean;
};

type IdToNewsData = Record<string, News>;
export type LocalizedNewsData = Partial<Record<LanguageType, IdToNewsData>>

export interface WebTemplate {
  templateHierarchy: WebTemplateData[];
  newData: NewsConfiguration[];
}

export type ElementWebTemplateData = {
  [propName: string]: LocalizedWebTemplateData;
}

export enum PageType {
  Template,
  Question,
  HallOfFame,
  HallOfFamePlayer,
  RosterArchive,
}

export interface UrlParams {
  topLevel?: string;
  secondLevel?: string;
  categoryName?: string;
  tab?: string;
}
