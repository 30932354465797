import React from 'react';
import { FormattedNumber, FormattedMessage, FormattedDate } from 'react-intl';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { MatchResult } from '@app/types/matchTypes';
import { MatchStatusMap } from '@app/constants/matchConstants';

import { useGameByOptaIdSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import { useCompetitionsSelector } from '@app/pages/Season/Season/SeasonHooks';
import {
  useMatchLiveSubscribe, useMatchResultsSelector, useMatchLiveResetOnUnmout,
} from './MatchDayHooks';

import { CountDown, Buttons } from './components';

interface MatchDayProps {
  result: MatchResult;
}

const NextMatchDay = ({ result }: MatchDayProps): React.ReactElement => {
  const matchResult = useMatchResultsSelector(result);
  const {
    gameOptaId, matchDay, matchDate, matchStatus, optaMatchTimeTBC, competitionOptaId, stadium,
  } = matchResult;
  const game = useGameByOptaIdSelector(gameOptaId);
  const competitions = useCompetitionsSelector();
  const competition = Object.values(competitions || {})
    .find(({ optaId }) => optaId === competitionOptaId);
  const matchTimeTBC = matchStatus === MatchStatusMap.upcoming && [
    optaMatchTimeTBC, game?.kenticoMatchTimeTBC,
  ].includes(true);

  const date = new Date(matchDate);

  useMatchLiveSubscribe(result);
  useMatchLiveResetOnUnmout(result);

  return (
    <Widget>
      <Heading>
        <CompetitionInfo>
          {competition?.logo?.url && (
            <CompetitionLogo
              src={competition.logo.url}
              alt={competition.logo.description}
            />
          )}
          <CompetitionName>
            <span>{competition?.name}</span>
            <span>
              <FormattedMessage id="match.day" />
              &nbsp;
              {matchDay}
            </span>
          </CompetitionName>
        </CompetitionInfo>
        <DateInfo>
          <FormattedDate value={date} weekday="short" />
          .&nbsp;
          <FormattedDate value={date} month="long" />
          &nbsp;|&nbsp;
          <span>
            <FormattedNumber value={date.getDate()} minimumIntegerDigits={2} />
          </span>
        </DateInfo>
      </Heading>

      <TeamInfo>
        <Sided>
          <Logo src={result.teams.first.logoUrl} alt={result.teams.first.name} />
        </Sided>
        <Centered>
          <StartTime>
            <span>
              {matchTimeTBC ? (<FormattedMessage id="match.tbc" />) : (
                <>
                  <FormattedNumber value={date.getHours()} minimumIntegerDigits={2} />
                  :
                  <FormattedNumber value={date.getMinutes()} minimumIntegerDigits={2} />
                </>
              )}
            </span>
          </StartTime>
        </Centered>
        <Sided>
          <Logo src={result.teams.second.logoUrl} alt={result.teams.second.name} />
        </Sided>
      </TeamInfo>

      <TeamInfo>
        <Sided>
          <FullName>
            {result.teams.first.name}
          </FullName>
          <ShortName>
            {result.teams.first.shortName.length ? result.teams.first.shortName : result.teams.first.name}
          </ShortName>
        </Sided>
        <Centered>
          <VS>
            <span>vs</span>
          </VS>
        </Centered>
        <Sided>
          <FullName>
            {result.teams.second.name}
          </FullName>
          <ShortName>
            {result.teams.second.shortName.length ? result.teams.second.shortName : result.teams.second.name}
          </ShortName>
        </Sided>
      </TeamInfo>

      <Stadium isVisible={Boolean(stadium)}>
        {stadium}
        &nbsp;
        <FormattedMessage id="match.stadium" />
      </Stadium>

      <StartTimeInfoArea>
        {matchTimeTBC ? (<FormattedMessage id="match.tbc.long" />) : (<CountDown date={date} />)}
      </StartTimeInfoArea>

      <CTA>
        <Buttons result={result} isWidget />
      </CTA>
    </Widget>
  );
};

export default NextMatchDay;

const Widget = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  height: auto;
  min-height: 320px;
  background: white;
  padding: 10px;

  @media ${Devices.tablet} {
    padding: 15px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  padding-bottom: 10px;
`;

const StartTimeInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: MilanTypeRev;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  text-align: center;
  color: #c9240d;
  min-height: 60px;
  text-transform: uppercase;
`;

const CompetitionInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 50%;
`;

const CompetitionLogo = styled.img`
  width: auto;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  margin-right: 10px;
`;

const CompetitionName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & span {
    font-family: Raleway;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: #000;

    &:first-child {
      font-weight: bold;
    }
  }
`;

const DateInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  flex: 0 0 50%;

  font-family: Raleway;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: right;
  color: #000;

  & span {
    font-size: 24px;
    font-weight: bold;
  }
`;

const TeamInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 60px;
  max-height: 60px;
`;

const FullName = styled.span`
  display: none;
  font-family: MilanTypeRev;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  text-transform: uppercase;
  word-break: break-word;

  @media ${Devices.tabletLarge} {
    display: inline-block;
  }
`;

const ShortName = styled(FullName)`
  display: inline-block;

  @media ${Devices.tabletLarge} {
    display: none;
  }
`;


const Centered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex: 0 0 120px;
`;

const Sided = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  flex: 0 0 calc(50% - 60px);
`;

const StartTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex: 0 0 auto;
  padding: 5px 10px;
  background: #c9240d;
  transform: skew(-21deg);

  & span {
    position: relative;
    font-family: Raleway;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.39px;
    color: white;
    text-transform: uppercase;
    transform: skew(21deg);
  }
`;

const VS = styled(StartTime)`
  padding: 2px 5px;
  background: #d2d2d2;

  & span {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.26px;
    background: #d2d2d2;
  }
`;

const Stadium = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }): number => (isVisible ? 0.5 : 0)};
  font-family: Raleway;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #000;
  padding: 10px;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;

  & > * {
    width: 120px;
    margin: 0 5px;
  }
`;
