import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { SecondLevelMenuItems } from '@app/components/SecondLevelMenu';
import {
  useLanguage,
  useBreadcrumbsJsonLD,
  useIsConfigLoaded,
} from '@app/components/Hooks';

import { mapSecondLevelMenuItems } from '@app/helpers/menuHelpers';
import {
  getTopLevelMenu, getSecondLevelMenu, getPageType, getLastLevelMenu, getWebTemplateData, getCurrentLocalizedTab,
} from '@app/helpers/webTemlateHelpers';

import {
  getWebTemplateData as getWebTemplateDataAction,
  resetWebTemplateData,
  setWebTemplatePageMultiLangRoute,
  resetWebTemplateError,
} from '@app/store/actions/webTemplateActions';

import { AppState } from '@app/store/reducers';
import {
  BaseMenuItem, MenuItem, LocalizedSubMenuItem, LocalizedMenuItem,
} from '@app/types/configurationTypes';
import {
  WebTabItem, WebTemplateData, PageType,
} from '@app/types/webTemplateTypes';
import { BreadcrumbsListSchema } from '@app/types/structuredMarkup';
import { Errors, PageError } from '@app/types/errorTypes';

export function useTopLevelMenu(): LocalizedMenuItem {
  const { topLevel = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, LocalizedMenuItem>(
    (state) => getTopLevelMenu(state, topLevel, language),
    isEqual,
  );
}

export function useSecondLevelMenu(): LocalizedSubMenuItem {
  const { secondLevel = '', topLevel = '' } = useParams();
  const language = useLanguage();

  return useSelector<AppState, LocalizedSubMenuItem>(
    (state) => getSecondLevelMenu(state, topLevel, secondLevel, language),
    isEqual,
  );
}

export function useThirdLevelMenu(): BaseMenuItem | undefined {
  const language = useLanguage();
  const { categoryName } = useParams();
  const secondLevelMenu = useSecondLevelMenu()[language];
  return secondLevelMenu?.categories.find((item) => item?.url === categoryName);
}

export function useLastLevelMenu(): MenuItem | BaseMenuItem | null {
  const language = useLanguage();
  const urlParams = useParams();

  return useSelector<AppState, MenuItem | BaseMenuItem | null>(
    (state) => getLastLevelMenu({ state, language, urlParams }),
    shallowEqual,
  );
}

export const useWebTemplateSecondLevelMenuItems = (): SecondLevelMenuItems => {
  const { secondLevel = '' } = useParams();
  const language = useLanguage();
  const topLevelMenu = useTopLevelMenu();
  const menuItem = topLevelMenu[language];
  return menuItem ? mapSecondLevelMenuItems({ menu: menuItem, selectedItemUrl: secondLevel }) : [];
};

export function useWebTemplateMultiLangUrl(): void {
  const dispatch = useDispatch();
  const {
    topLevel = '', secondLevel = '', categoryName = '', tab = '',
  } = useParams();
  const language = useLanguage();
  const topLevelMenu = useTopLevelMenu();

  useEffect(() => {
    if (topLevelMenu) {
      dispatch(setWebTemplatePageMultiLangRoute(({
        topLevel,
        secondLevel,
        categoryName,
        tab,
        language,
      })));
    }
  }, [topLevelMenu]);
}

export function useWebTemplateDownload(): void {
  const {
    topLevel = '', secondLevel = '', categoryName = '',
  } = useParams();
  const language = useLanguage();
  const lastLevelMenu = useLastLevelMenu();
  const dispatch = useDispatch();

  const hasTemplateItems = useSelector<AppState, boolean>(
    (state) => !!state.webTemplate.templates[lastLevelMenu?.id ?? ''],
    shallowEqual,
  );

  useEffect(() => {
    if (!hasTemplateItems) {
      dispatch(resetWebTemplateData());
      dispatch(getWebTemplateDataAction({
        topLevel, secondLevel, categoryName, language,
      }));
    }

    return (): void => { dispatch(resetWebTemplateError()); };
  }, [topLevel, secondLevel, categoryName]);
}

export function useWebTemplateData(): WebTemplateData[] | null {
  const language = useLanguage();
  const { topLevel = '', secondLevel = '', categoryName = '' } = useParams();

  return useSelector<AppState, WebTemplateData[] | null>(
    (state) => getWebTemplateData({ state, language, urlParams: { topLevel, secondLevel, categoryName } }),
    shallowEqual,
  );
}

export function useWebTemplateError(): PageError | null {
  const language = useLanguage();
  return useSelector<AppState, PageError | null>(
    (state) => state.webTemplate.error[language] ?? null,
    shallowEqual,
  );
}

export function useWebTemplateLoading(): boolean {
  return useSelector<AppState, boolean>(
    (state) => state.webTemplate.isLoading,
    shallowEqual,
  );
}

function useCurrentTab(): WebTabItem | null {
  const language = useLanguage();
  const urlParams = useParams();

  return useSelector<AppState, WebTabItem | null>(
    (state) => getCurrentLocalizedTab({ state, language, urlParams })[language] ?? null,
    shallowEqual,
  );
}

export function useWebTemplatePageValidation(): Errors {
  const language = useLanguage();
  const { secondLevel, categoryName, tab } = useParams();
  const topLevel = useTopLevelMenu()[language];
  const secondLevelMenu = useSecondLevelMenu()[language];
  const thirdLevelMenu = useThirdLevelMenu();
  const error = useWebTemplateError();

  const isLoading = useWebTemplateLoading();
  const isConfigLoaded = useIsConfigLoaded();

  const currentTab = useCurrentTab();

  if (error) {
    return error;
  }

  const isSecondLevelIncorrect = (!!secondLevel && !secondLevelMenu) || (!secondLevel && !!secondLevelMenu);
  const isThirdLevelIncorrect = !!categoryName && !tab && (!thirdLevelMenu && !currentTab);
  const isFourthLevelIncorrect = tab && !currentTab;

  // 1. Check whether top level route path correct
  // 2. Check whether second level route path correct
  // 3. Check whether third level route path correct or check tab route on third level if tabs available
  // 4. Check whether tab route on forth level if tabs available
  return !isLoading && isConfigLoaded && (!topLevel || isSecondLevelIncorrect
    || isThirdLevelIncorrect || isFourthLevelIncorrect) ? PageError.NotFound : null;
}

type UseWebTemplateJsonLD = () => BreadcrumbsListSchema

export const useWebTemplateJsonLD: UseWebTemplateJsonLD = () => {
  const language = useLanguage();
  const topLevel = useTopLevelMenu()[language];
  const secondLevelMenu = useSecondLevelMenu()[language];
  const thirdLevelMenu = useThirdLevelMenu();
  const currentTab = useCurrentTab();

  const lastLevelMenu = currentTab || thirdLevelMenu || secondLevelMenu || topLevel;
  const pageTitle = lastLevelMenu?.seo?.title ?? '';
  let secondLevelPageData;
  const category = secondLevelMenu?.categories[0];

  if (topLevel && secondLevelMenu && thirdLevelMenu && category?.url !== lastLevelMenu?.url) {
    const secondLevelPageTitle = category ? category.seo.title : secondLevelMenu.seo.title;

    secondLevelPageData = {
      pageTitle: secondLevelPageTitle,
      linkParams: {
        pathKey: 'routes.webTemplate',
        pathParams: {
          topLevel: topLevel.url,
          secondLevel: secondLevelMenu.url,
          categoryName: category?.url ?? '',
        },
      },
    };
  }

  return useBreadcrumbsJsonLD(pageTitle, secondLevelPageData);
};

export const useIsYouthTeam = (webCardLinkId: string): boolean => {
  const language = useLanguage();
  return useSelector<AppState, boolean>(
    (state) => {
      const teams = state.configuration[language].menu?.web_teams?.navigation?.youth_teams?.categories ?? [];
      return Boolean(teams.find(({ id }) => id === webCardLinkId));
    },
    shallowEqual,
  );
};

export const usePageType = (): PageType => {
  const language = useLanguage();
  const { tab = '' } = useParams();
  const secondLevelMenu = useSecondLevelMenu()[language];
  return getPageType(secondLevelMenu, tab);
};
