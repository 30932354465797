import React from 'react';

import { WebTemplateData, WebTemplateTypes } from '@app/types/webTemplateTypes';
import { NewsContent } from '@app/components';
import {
  WebLandingElement, WebDepartmentElement, WebPartnerElement, WebPersonElement, WebCardElement, WebPageImageElement,
  WebPageTitleElement, WebTabsElement, WebCtaButtonElement, WebTicketsPartnersElement,
  WebShareElement, WebFaqCategoryGroupElement, WebFaqCategoryElement, WebCtaImageElement, WebCtaDuoButtonElement,
  WebEditorialDuoElement, WebEmbeddedElement, WebContentGroupElement, WebGoogleAdsElement, WebGoogleMapsElement,
  WebHomeLandingElement, WebEmbeddedOverlayElement, WebPageSeparatorElement, WebPageVideoElement,
  WebEmbeddedOneTrustElement, WebCardRosterArchiveElement,
} from './elements';
import { ContainerCta, WebEditorialContainer } from './components';

interface WebTemplateSelectProps {
  data: WebTemplateData;
}

const WebTemplateSelect = ({ data }: WebTemplateSelectProps): React.ReactElement | null => {
  switch (data?.type) {
    case WebTemplateTypes.WebGoogleMaps:
      return (<WebGoogleMapsElement data={data} />);
    case WebTemplateTypes.WebGoogleAds:
      return (<WebGoogleAdsElement data={data} />);
    case WebTemplateTypes.WebDepartment:
      return (<WebDepartmentElement data={data} />);
    case WebTemplateTypes.WebPerson:
      return (<WebPersonElement data={data} />);
    case WebTemplateTypes.WebPartner:
      return (<WebPartnerElement data={data} type={data.id} />); // TODO:REMOVE:TYPE
    case WebTemplateTypes.WebLanding:
      return (<WebLandingElement data={data} />);
    case WebTemplateTypes.WebCardRosterArchive:
      return (<WebCardRosterArchiveElement data={data} />);
    case WebTemplateTypes.WebCard:
      return (<WebCardElement data={data} />);
    case WebTemplateTypes.WebPageImage:
      return (<WebPageImageElement data={data} />);
    case WebTemplateTypes.WebPageVideo:
      return (<WebPageVideoElement data={data} />);
    case WebTemplateTypes.WebPageTitle:
      return (<WebPageTitleElement data={data} />);
    case WebTemplateTypes.WebPageSeparator:
      return (<WebPageSeparatorElement size={data?.size} />);
    case WebTemplateTypes.WebEditorial:
      return (<WebEditorialContainer><NewsContent content={data?.content ?? ''} /></WebEditorialContainer>);
    case WebTemplateTypes.WebEditorialDuo:
      return (<WebEditorialDuoElement data={data} />);
    case WebTemplateTypes.WebEmbedded:
      return (<WebEmbeddedElement content={data?.content ?? ''} />);
    case WebTemplateTypes.WebEmbeddedOneTrust:
      return (<WebEmbeddedOneTrustElement content={data?.content ?? ''} />);
    case WebTemplateTypes.WebEmbeddedOverlay:
      return (<WebEmbeddedOverlayElement content={data?.content ?? ''} delay={data?.delay ?? 0} />);
    case WebTemplateTypes.WebTabs:
      return (<WebTabsElement data={data} />);
    case WebTemplateTypes.WebCtaButton:
      return (<ContainerCta><WebCtaButtonElement data={data} /></ContainerCta>);
    case WebTemplateTypes.WebCtaImage:
      return (<ContainerCta><WebCtaImageElement data={data} /></ContainerCta>);
    case WebTemplateTypes.WebCtaDuoButton:
      return (<WebCtaDuoButtonElement data={data} />);
    case WebTemplateTypes.WebTicketsPartners:
      return (<WebTicketsPartnersElement data={data} />);
    case WebTemplateTypes.WebShare:
      return (<WebShareElement />);
    case WebTemplateTypes.WebFaqCategoryGroup:
      return (<WebFaqCategoryGroupElement data={data} />);
    case WebTemplateTypes.WebFaqCategory:
      return (<WebFaqCategoryElement data={data} />);
    case WebTemplateTypes.WebContentGroup:
      return (<WebContentGroupElement data={data} />);
    case WebTemplateTypes.WebHomeLanding:
      return (<WebHomeLandingElement data={data} />);
    default:
      return null;
  }
};

export default WebTemplateSelect;
