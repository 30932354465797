import * as Translations from '@app/locales';

import { waitForConfigurationDownload } from '@app/store/actions/configurationActions';
import { CurrentRouteParams, setCurrentRoute } from '@app/store/actions/currentRouteActions';
import { getHallOfFameData } from '@app/store/actions/hallOfFameActions';
import { getQuestionData } from '@app/store/actions/questionActions';

import * as WebTemplateService from '@app/services/kentico/webTemplateService';
import { getNewsList } from '@app/services/kentico/newsService';

import { Action, ActionWithPromiseReturn } from '@app/types/actionTypes';
import {
  ResetWebTemplateData,
  SetWebTemplateData,
  LoadingWebTemplateData,
  ResetWebTemplateError,
  SetWebTemplateError,
  WebTemplateActionTypes,
  SetNewsData,
  SetNewsDataActionPayload,
  SetNewsLoadingStateActionPayload,
  SetNewsLoadingState,
  SetWebTemplateDataPayload,
} from '@app/store/actionTypes/webTemplateActionTypes';
import {
  News, LocalizedWebTemplateData, LocalizedNewsData, PageType,
} from '@app/types/webTemplateTypes';
import { LanguageType } from '@app/types/localizationTypes';
import { PageError } from '@app/types/errorTypes';
import { EditorialContentTypes } from '@app/types/newsTypes';

import { AppLanguages } from '@app/constants/localizationConstants';
import { ApplicationConfig } from '@app/constants/configurationConstants';
import AppRoutes from '@app/constants/routes';

import { findCategoryById, findCategoryBySlug } from '@app/helpers/configurationHelpers';
import {
  getTopLevelMenu,
  getSecondLevelMenu,
  getMenuSubItem,
  isQuestion,
  getNewsDataById,
  getPageType,
  isHallOfFamePlayer,
  getCurrentLocalizedTab,
} from '@app/helpers/webTemlateHelpers';
import { mapNewsItems } from '@app/helpers/newsHelpers';
import { getPlayer } from '@app/helpers/hallOfFameHelpers';

const setWebTemplateData = (data: SetWebTemplateDataPayload): SetWebTemplateData => ({
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_DATA,
  payload: data,
});

const setNewsData = (data: SetNewsDataActionPayload): SetNewsData => ({
  type: WebTemplateActionTypes.SET_NEWS_DATA,
  payload: data,
});

const setNewsLoadingState = (data: SetNewsLoadingStateActionPayload): SetNewsLoadingState => ({
  type: WebTemplateActionTypes.SET_NEWS_LOADING_STATE,
  payload: data,
});

export const resetWebTemplateData = (): ResetWebTemplateData => ({
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_DATA,
});

export const loadingWebTemplateData = (state: boolean): LoadingWebTemplateData => ({
  type: WebTemplateActionTypes.LOADING_WEB_TEMPLATE_DATA,
  payload: state,
});

export const setWebTemplateError = (language: LanguageType, error: PageError): SetWebTemplateError => ({
  type: WebTemplateActionTypes.SET_WEB_TEMPLATE_ERROR,
  payload: {
    [language]: error,
  },
});

export const resetWebTemplateError = (): ResetWebTemplateError => ({
  type: WebTemplateActionTypes.RESET_WEB_TEMPLATE_ERROR,
});

interface GetWebTemplateDataByIdProps {
  id: string;
  language: LanguageType;
}

export const getWebTemplateNews = (newsGroupId: string, language: LanguageType): ActionWithPromiseReturn => (
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(setNewsLoadingState(({
        id: newsGroupId,
        isLoading: true,
        language,
      })));
      const state = getState();
      const newsData = getNewsDataById({ state, id: newsGroupId, language });

      if (!newsData) return;

      const news = await getNewsList({
        type: newsData?.type ?? [EditorialContentTypes.NEWS],
        limit: newsData.layoutConfiguration.itemsToDownload || ApplicationConfig.news.defaultNewsLimit,
        skip: newsData.items.length,
        category: newsData.categories,
        tags: newsData.tags,
        language,
      });

      dispatch(setNewsData({ id: newsGroupId, newsData: mapNewsItems(news), language }));
    } catch (e) {
      console.error('Error on fetching additional news', e);
    } finally {
      dispatch(setNewsLoadingState(({
        id: newsGroupId,
        isLoading: false,
        language,
      })));
    }
  }
);

export const getWebTemplateDataById = ({ id, language }: GetWebTemplateDataByIdProps): ActionWithPromiseReturn => (
  async (dispatch): Promise<void> => {
    const webTemplateData = await WebTemplateService.getWebTemplateData(id, language);

    const newsRequests = webTemplateData.newData.map(async (data) => {
      const news = await getNewsList({
        type: data?.type ?? [EditorialContentTypes.NEWS],
        limit: data?.layoutConfiguration?.itemsToDownload || ApplicationConfig.news.defaultNewsLimit,
        skip: 0,
        category: data.categories,
        tags: data.tags,
        language,
      });

      return {
        ...data,
        ...mapNewsItems(news),
      };
    });

    const news = await Promise.all(newsRequests);

    const idToNewMap = news.reduce((acc, news) => {
      acc[news.id] = {
        ...news,
        isLoadingData: false,
      };
      return acc;
    }, {} as Record<string, News>);

    const localizedWebTemplateData: LocalizedWebTemplateData = {};
    localizedWebTemplateData[language] = webTemplateData.templateHierarchy;
    const newsData: LocalizedNewsData = {};
    newsData[language] = idToNewMap;

    dispatch(setWebTemplateData({
      templates: {
        data: localizedWebTemplateData,
        id,
      },
      newsData,
    }));
  }
);

interface WebTemplatePageParams {
  topLevel: string;
  secondLevel: string;
  categoryName: string;
  language: LanguageType;
}

type WebTemplateAction = (params: WebTemplatePageParams) => ActionWithPromiseReturn

export const getWebTemplateData: WebTemplateAction = ({
  topLevel, secondLevel, categoryName, language,
}) => (
  async (dispatch, getState): Promise<void> => {
    dispatch(loadingWebTemplateData(true));

    await waitForConfigurationDownload();

    try {
      const lastLevelMenu = getMenuSubItem({
        state: getState(), topLevel, secondLevel, categoryName, language,
      });

      if (lastLevelMenu) {
        dispatch(resetWebTemplateError());
        await Promise.all(AppLanguages.map(async (language) => {
          try {
            await dispatch(getWebTemplateDataById({
              id: lastLevelMenu.id,
              language,
            }));
          } catch (e) {
            console.error('Error on fetching players', e);
            dispatch(setWebTemplateError(language, PageError.Sorry));
          }
        }));
      }
    } catch (e) {
      console.error('Error on fetching players', e);
    } finally {
      dispatch(loadingWebTemplateData(false));
    }
  }
);

interface WebTemplateParams extends WebTemplatePageParams{
  tab: string;
}

type SetWebTemplatePageMultiLangRoute = (params: WebTemplateParams) => ActionWithPromiseReturn
export const setWebTemplatePageMultiLangRoute: SetWebTemplatePageMultiLangRoute = (urlParams) => (
  async (dispatch, getState): Promise<void> => {
    await waitForConfigurationDownload();
    const {
      topLevel, secondLevel, categoryName, tab, language,
    } = urlParams;
    const state = getState();
    const topLevelMenu = getTopLevelMenu(state, topLevel, language);
    const secondLevelMenu = getSecondLevelMenu(state, topLevel, secondLevel, language);
    const categoryId = findCategoryBySlug(secondLevelMenu?.[language]?.categories ?? [], categoryName)?.id ?? '';
    const isFaqQuestion = isQuestion(secondLevelMenu?.[language]?.id ?? '', tab);
    const isHallOfFamePlayerPage = isHallOfFamePlayer(secondLevelMenu?.[language]?.id ?? '', tab);

    const currentRouteParams = {
      topLevel: {}, secondLevel: {}, categoryName: {}, tab: {},
    } as unknown as CurrentRouteParams;

    const currentLocalizedTab = getCurrentLocalizedTab({ language, state, urlParams });

    const params = AppLanguages.reduce((acc, language) => {
      const currentTab = currentLocalizedTab[language];
      acc.topLevel[language] = topLevelMenu[language]?.url ?? '';
      acc.secondLevel[language] = secondLevelMenu[language]?.url ?? '';
      acc.categoryName[language] = findCategoryById(secondLevelMenu?.[language]?.categories ?? [], categoryId)?.url ?? '';

      if (isFaqQuestion) {
        acc.tab[language] = state.question[language]?.question?.urlSlug ?? '';
      } else if (isHallOfFamePlayerPage) {
        acc.tab[language] = getPlayer({ state, language, playerUrlSlug: tab })?.urlSlug ?? '';
        acc.categoryName[language] = Translations[language]?.['hallOfFame.playerThirdLevelUrl'] ?? '';
      } else if (currentTab?.url && tab) {
        acc.tab[language] = currentTab.url ?? '';
      } else if (currentTab?.url) {
        acc.categoryName[language] = currentTab.url ?? '';
      }

      return acc;
    }, currentRouteParams);

    const hasTabs = Object.values(params.tab).length;

    dispatch(setCurrentRoute({
      pathId: hasTabs ? AppRoutes.WebTemplateTab.path : AppRoutes.WebTemplate.path,
      params,
    }));
  }
);

type WebTemplateSSRAction = (params: WebTemplateParams) => Action
type PromiseData = void | void[];

export const getWebTemplateDataForSsr: WebTemplateSSRAction = (params) => (
  async (dispatch, getState): Promise<PromiseData | PromiseData[]> => {
    if (!params.topLevel) return;

    await waitForConfigurationDownload();

    const {
      topLevel, secondLevel, tab, language,
    } = params;
    const state = getState();
    const secondLevelMenu = getSecondLevelMenu(state, topLevel, secondLevel, language);
    const pageType = getPageType(secondLevelMenu[language], tab);

    switch (pageType) {
      case PageType.Question:
        await dispatch(getQuestionData(params));
        return dispatch(setWebTemplatePageMultiLangRoute(params));
      case PageType.HallOfFamePlayer:
        await dispatch(getHallOfFameData(params));
        return dispatch(setWebTemplatePageMultiLangRoute(params));
      case PageType.HallOfFame:
        return Promise.all([
          dispatch(setWebTemplatePageMultiLangRoute(params)),
          dispatch(getHallOfFameData(params)),
        ]);
      default:
        await dispatch(getWebTemplateData(params));
        return dispatch(setWebTemplatePageMultiLangRoute(params));
    }
  }
);
