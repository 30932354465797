import { mapImageData, mapSeoData } from '@app/helpers/configurationHelpers';

import { KenticoLangToLangCodeMap } from '@app/constants/localizationConstants';
import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';
import { KenticoHallOfFameList } from '../types/hallOfFameTypes';

export const convertHallOfFame = (data: KenticoHallOfFameList): HallOfFamePlayer[] => (
  data.map(({ elements, system }) => (
    {
      id: elements.id?.value ?? '',
      firstName: elements.first_name?.value ?? '',
      lastName: elements.last_name?.value ?? '',
      hallOfFame: elements.teams?.value?.map(({ codename }) => codename) ?? [],
      number: (elements.number?.value ?? '').toString(),
      birthDate: elements.birth_date?.value ?? '',
      birthPlace: elements.birth_place?.value ?? '',
      totalMatches: elements.total_matches?.value ?? '',
      totalGoals: elements.total_goals?.value ?? '',
      serieAMatches: elements.seriea_matches?.value ?? '',
      serieAGoals: elements.seriea_goals?.value ?? '',
      biography: elements.biography?.value ?? '',
      smallPhoto: mapImageData(elements.photo_list),
      largePhoto: mapImageData(elements.photo_large),
      backgroundPhoto: mapImageData(elements.photo_background),
      urlSlug: elements.url?.value ?? '',
      ...mapSeoData(elements, KenticoLangToLangCodeMap[system?.language], true),
    }
  ))
);
