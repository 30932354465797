import React, { useState } from 'react';
import styled from 'styled-components';

import { Devices } from '@app/styles';
import { ImageConfiguration } from '@app/types/imageTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { WebCardLayoutSize, WebCardLayoutType, WebCardRosterArchive } from '@app/types/webTemplateTypes';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { getFullScreenImgConfig, getResponsiveImageConfig } from '@app/helpers/imageHelper';

import { Image } from '@app/components';
import WebCtaButtonElement from '@app/components/WebTemplates/elements/WebCtaButtonElement';
import { useIsYouthTeam } from '@app/pages/WebTemplate/WebTemplateHooks';
import { useLanguage } from '@app/components/Hooks';

type WebCardButtonBlockProps = React.PropsWithChildren<{
  layoutSize?: WebCardLayoutSize;
}>

const ButtonBlock = ({ layoutSize, children }: WebCardButtonBlockProps): React.ReactElement | null => {
  switch (layoutSize) {
    case WebCardLayoutSize.Large:
      return <ButtonBlockLarge>{children}</ButtonBlockLarge>;
    case WebCardLayoutSize.Medium:
      return <ButtonBlockMedium>{children}</ButtonBlockMedium>;
    case WebCardLayoutSize.Small:
      return <ButtonBlockSmall>{children}</ButtonBlockSmall>;
    default:
      return null;
  }
};

const Container = ({ layoutSize, children }: WebCardButtonBlockProps): React.ReactElement | null => {
  switch (layoutSize) {
    case WebCardLayoutSize.Large:
      return <ContainerLarge>{children}</ContainerLarge>;
    case WebCardLayoutSize.Medium:
      return <ContainerMedium>{children}</ContainerMedium>;
    case WebCardLayoutSize.Small:
      return <ContainerSmall>{children}</ContainerSmall>;
    default:
      return null;
  }
};

interface WebCardRosterArchiveElementProps {
  data: WebCardRosterArchive;
}

const WebCardRosterArchiveElement = ({ data }: WebCardRosterArchiveElementProps): React.ReactElement => {
  const [isImageLoaded, setImageLoadedState] = useState(false);

  const overlay = data?.layoutType === WebCardLayoutType.Overlay;
  const layoutSize = data?.layoutSize ?? WebCardLayoutSize.Small;
  const backgroundUrl = data?.backgroundImage?.url;
  const buttonData = { ...data?.cta?.[0] };

  const isYouthTeam = useIsYouthTeam(data.link?.id ?? '');
  const language = useLanguage();

  const onCtaClick = (): void => {
    if (isYouthTeam) {
      pushGAEvent({
        event: GA_EVENT.CLICK_YOUTH,
        category: GA_CATEGORY.YOUTH_TEAM,
        label: data.title ?? '',
        language,
      });
    } else if (data.analytic) {
      pushGAEvent({ ...data.analytic, language });
    }
  };

  let imageConfigGetter;

  if (data.link) buttonData.menuItem = data.link;

  switch (data?.layoutSize) {
    case WebCardLayoutSize.Large:
      imageConfigGetter = (backgroundUrl): ImageConfiguration => getFullScreenImgConfig(backgroundUrl);
      break;
    case WebCardLayoutSize.Medium:
      imageConfigGetter = (backgroundUrl): ImageConfiguration => (
        getResponsiveImageConfig(backgroundUrl, { 600: 470, 415: 560, 0: 375 })
      );
      break;
    case WebCardLayoutSize.Small:
      imageConfigGetter = (backgroundUrl): ImageConfiguration => (
        getResponsiveImageConfig(
          backgroundUrl,
          {
            1024: 302, 600: 450, 376: 559, 0: 335,
          },
        )
      );
      break;
    default:
      break;
  }
  console.log('ROSTER CARD');

  return (
    <Container layoutSize={layoutSize}>
      <RosterArchiveSeoWrapper>
        { backgroundUrl && (
          <Image
            src={backgroundUrl}
            description={data.backgroundImage.description}
            additionalImageProps={imageConfigGetter(backgroundUrl)}
            onImageLoad={(): void => setImageLoadedState(true)}
            onPreloadImageLoad={(): void => setImageLoadedState(true)}
            useLazyLoading
          />
        )}
        {
          isImageLoaded && (
            <Info overlay={overlay}>
              <Title overlay={overlay} layoutSize={layoutSize}>
                {data?.title}
              </Title>
              {data?.subTitle && (
                <SubTitle overlay={overlay} layoutSize={layoutSize}>
                  {data?.subTitle}
                </SubTitle>
              )}
              <ButtonBlock layoutSize={layoutSize}>
                <WebCtaButtonElement
                  data={buttonData}
                  onClick={onCtaClick}
                  disableSeoTitleTag
                />
              </ButtonBlock>
            </Info>
          )
        }
      </RosterArchiveSeoWrapper>
    </Container>
  );
};

const ContainerCommon = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const RosterArchiveSeoWrapper = styled.h2`
  margin: unset;
  display: flex;
`;

const ContainerLarge = styled(ContainerCommon)`
  margin-bottom: 0;

  @media ${Devices.tablet} {
    width: 100%;
  }
`;

const ContainerSmall = styled(ContainerCommon)`
  @media ${Devices.tablet} {
    width: calc(50% - 10px);
    max-width: 470px;

    &:nth-of-type(2n-1) {
      margin-right: 20px;
    }
  }

  @media ${Devices.desktop} {
    width: calc(33% - 15px);
    margin-right: 20px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
`;

const ContainerMedium = styled(ContainerCommon)`
  @media ${Devices.tablet} {
    width: calc(50% - 10px);
    margin-top: 10px;
    margin-bottom: 10px;

    &:nth-of-type(2n-1) {
      margin-right: 20px;
    }
  }
`;

const Info = styled.div<{ overlay: boolean }>`
  position: ${({ overlay }): string => (overlay ? 'absolute' : 'relative')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({ overlay }): string => (overlay ? '5px' : '15px')};
  color: ${({ overlay }): string => (overlay ? '#ffffff' : '#000000')};
  background-color: ${({ overlay }): string => (overlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};

  @media ${Devices.tablet} {
    padding: ${({ overlay }): string => (overlay ? '10px' : '20px')};
  }

  @media ${Devices.desktop} {
    padding: ${({ overlay }): string => (overlay ? '15px' : '20px')};
    background-color: ${({ overlay }): string => (overlay ? 'rgba(0, 0, 0, 0.4)' : 'transparent')};
  }
`;

const Title = styled.p<{ overlay: boolean; layoutSize: WebCardLayoutSize }>`
  display: block;
  max-width: 440px;
  margin: 0 auto;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.18;
  letter-spacing: 0.5px;
  text-align: center;

  @media ${Devices.tablet} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '16px' : '18px')};
  }

  @media ${Devices.desktop} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '16px' : '22px')};
  }
`;

const SubTitle = styled.p<{ overlay: boolean; layoutSize: WebCardLayoutSize }>`
  display: block;
  width: ${({ overlay }): string => (overlay ? '250px' : '100%')};
  min-height: 35px;
  margin: 5px auto 0;
  font-family: ${(props): string => props.theme.fontFamily.raleway};
  font-size: 11px;
  text-align: center;

  @media ${Devices.tablet} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '11px' : '14px')};
    height: ${({ overlay }): string => (overlay ? 'auto' : '35px')};
    overflow: ${({ overlay }): string => (overlay ? 'unset' : 'hidden')};
  }

  @media ${Devices.desktop} {
    font-size: ${({ layoutSize }): string => ([WebCardLayoutSize.Small, WebCardLayoutSize.Medium].includes(layoutSize) ? '11px' : '16px')};
}
`;

const ButtonBlockLarge = styled.div`
  margin-top: 5px;
  width: 170px;

  @media ${Devices.tablet} {
    margin-top: 20px;
  }

  @media ${Devices.desktop} {
    margin-top: 30px;
    width: 250px;
  }
`;

const ButtonBlockMedium = styled.div`
  margin-top: 5px;
  width: 200px;

  @media ${Devices.tablet} {
    margin-top: 10px;
  }

  @media ${Devices.desktop} {
    margin-top: 20px;
    width: 250px;
  }
`;

const ButtonBlockSmall = styled.div`
  margin-top: 5px;
  width: 200px;

  @media ${Devices.desktop} {
    margin-top: 10px;
  }
`;

export default WebCardRosterArchiveElement;
