import React from 'react';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';

import * as Translations from '@app/locales/index';

import AppRoutes from '@app/constants/routes';
import { theme } from '@app/styles';
import {
  BuyTicketsButton, ColorizedExternalLink, MatchCenterButton, MatchInfoButton, TicketAvailabilityButton,
} from '@app/components/Buttons';
import { MatchResult } from '@app/types/matchTypes';
import { GA_CATEGORY, GA_EVENT } from '@app/types/googleTypes';
import { MatchStatusMap } from '@app/constants/matchConstants';
import { MatchDayDateFormatMap } from '@app/constants/dateTimeConstants';

import { pushGAEvent } from '@app/helpers/googleHelpers';
import { useLanguage } from '@app/components/Hooks';
import { useGameByOptaIdSelector } from '@app/pages/MatchCenter/MatchCenterHooks';
import {
  useCompetitionCategorySelector, useSeasonIdActiveStateCheck, useSyncCalendarUrlSelector,
} from '@app/pages/Season/Season/SeasonHooks';
import {
  useMatchTicketSelector, useMatchResultsSelector,
} from '../MatchDayHooks';


interface MatchDayProps {
  result: MatchResult;
  isHomePage?: boolean;
  isWidget?: boolean;
}

const Buttons = ({ result, isHomePage = false, isWidget = false }: MatchDayProps): React.ReactElement => {
  const { gameOptaId, matchDate, matchStatus } = useMatchResultsSelector(result);
  const language = useLanguage();

  const date = new Date(matchDate);
  const today = new Date();

  const game = useGameByOptaIdSelector(gameOptaId);
  const tickets = useMatchTicketSelector(result);
  const categoryId = useCompetitionCategorySelector();
  const syncCalendarUrl = useSyncCalendarUrlSelector();
  const isSeasonIdActive = useSeasonIdActiveStateCheck();

  const isMatchUpcoming = matchStatus === MatchStatusMap.upcoming;
  const isMatchFinished = matchStatus === MatchStatusMap.finished;
  const isMatchLive = matchStatus === MatchStatusMap.live;

  const isOneHourBeforeMatch = date.getTime() > today.getTime()
    && Math.round(Math.abs(date.getTime() - today.getTime()) / 36e5) <= 1;

  const useMatchCenterButton = (isMatchFinished || isMatchLive || isOneHourBeforeMatch) && game?.urlSlug;

  const useBuyTicketsButton = !useMatchCenterButton
    && !!game?.ticketsActive;

  const useTicketAvailabilityButton = !useMatchCenterButton
    && !game?.ticketsActive
    && !!game?.ticketsSoon;

  const useAddToCalendarButton = !useMatchCenterButton
    && !!syncCalendarUrl.length
    && isSeasonIdActive
    && isMatchUpcoming
    && !isWidget;

  // Google Analytic
  const onMatchCenterButtonClick = (): void => {
    const analytic = isHomePage ? {
      event: GA_EVENT.CLICK_HOME,
      category: GA_CATEGORY.MATCHES,
      match: `${result.teams.first.name} - ${result.teams.second.name}`,
      label: 'matchcenter',
      language,
    } : {
      event: GA_EVENT.CLICK_SCHEDULE,
      category: GA_CATEGORY.MATCHES,
      match: `${result.teams.first.name} - ${result.teams.second.name}`,
      action: format(new Date(result?.matchDate ?? 0), MatchDayDateFormatMap[language]),
      label: categoryId,
      language,
    };

    pushGAEvent(analytic);
  };

  return (
    <>
      {useBuyTicketsButton && tickets && (
        <BuyTicketsButton matchTickets={tickets} />
      )}
      {useTicketAvailabilityButton && game && (
        <TicketAvailabilityButton game={game} />
      )}
      {useAddToCalendarButton && (
        <ColorizedExternalLink
          color="white"
          borderColor={theme.color.freeSpeechRed}
          invertColor="white"
          invertBackgroundColor={theme.color.freeSpeechRed}
          url={syncCalendarUrl}
        >
          <FormattedMessage id="season.addToCalendar" />
        </ColorizedExternalLink>
      )}
      {useMatchCenterButton && (
        <MatchCenterButton
          pathKey={AppRoutes.MatchCenter.path}
          pathParams={{
            urlSlug: game?.urlSlug ?? '',
            tab: Translations[language]?.['matchcenter.tabs.lineups'].toLowerCase(),
          }}
          onClick={onMatchCenterButtonClick}
        >
          <FormattedMessage id="match.center" />
        </MatchCenterButton>
      )}
      {game?.ticketsPopup && (
        <MatchInfoButton game={game} />
      )}
    </>
  );
};

export default Buttons;
