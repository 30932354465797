import { sendRequestTyped } from '@app/services/kentico/request';
import { langCodeToKenticoLangMap } from '@app/constants/localizationConstants';

import { LanguageType } from '@app/types/localizationTypes';
import { HallOfFamePlayer } from '@app/types/hallOfFameTypes';
import { KenticoHallOfFameElement } from './types/hallOfFameTypes';

import { convertHallOfFame } from './converters/HallOfFameConverter';

export const getHallOfFame = async (language: LanguageType): Promise<HallOfFamePlayer[]> => {
  const hallOfFame = await sendRequestTyped<KenticoHallOfFameElement>({
    params: {
      'system.type': 'player_halloffame',
      language: langCodeToKenticoLangMap[language],
      'system.language': langCodeToKenticoLangMap[language],
    },
  });

  return convertHallOfFame(hallOfFame?.items ?? []);
};
