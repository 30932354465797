import * as React from 'react';
import styled from 'styled-components';

import { NavigationLinkParams } from '@app/types/routerTypes';
import { LocalizedLink } from '@app/components/Localization';
import { HorizontalScroll } from '@app/components';
import { Devices } from '@app/styles';
import {
  MenuItemTypes, ExternalLink, RelatedMenuItem, GeoTargetingData,
} from '@app/types/configurationTypes';
import { checkGetTargetingData, formatRelatedItemLinkProps } from '@app/helpers/menuHelpers';
import { useMenSquadAllCompetitionsParams } from '@app/pages/Season/Season/SeasonHooks';
import { useUserCountry } from '@app/components/Header/HeaderHooks';

export type SecondLevelMenuItems = {
  id: string;
  name: string;
  isActive: boolean;
  linkData: NavigationLinkParams;
  type: MenuItemTypes;
  external: {
    name: string;
    url: ExternalLink;
  };
  related: RelatedMenuItem;
  isVisible: boolean;
  geo: GeoTargetingData;
}[]

interface SecondLevelMenuProps {
  items: SecondLevelMenuItems;
  usePadding?: boolean;
  backgroundColor?: string;
  isTabs?: boolean;
}

const SecondLevelMenu = ({
  items, usePadding = true, isTabs = false, backgroundColor,
}: SecondLevelMenuProps): React.ReactElement | null => {
  const { seasonId, urlSlug } = useMenSquadAllCompetitionsParams();

  const userCountry = useUserCountry();
  const subItems = items
    .filter(({ isVisible, type, geo }) => (isVisible && checkGetTargetingData(geo, userCountry))
      || type === MenuItemTypes.ExternalLink);

  return subItems?.length > 0 ? (
    <Container backgroundColor={backgroundColor}>
      <Menu usePadding={usePadding}>
        <HorizontalScroll color="white">
          {
            subItems.map(({
              id, name, linkData, isActive, type, external, related,
            }) => (
              <MenuItem key={id}>
                {((): React.ReactElement => {
                  switch (type) {
                    case MenuItemTypes.ExternalLink:
                      return (
                        <MenuItemLink as="a" {...external.url}>
                          {external.name}
                        </MenuItemLink>
                      );
                    case MenuItemTypes.RelatedMenuItem:
                      return (
                        <MenuItemLink
                          {...formatRelatedItemLinkProps(related, seasonId, urlSlug)}
                          isTab={isTabs}
                        >
                          {related.name}
                        </MenuItemLink>
                      );
                    default:
                      return (
                        <MenuItemLink
                          pathKey={linkData.pathKey}
                          pathParams={linkData.pathParams}
                          isActive={isActive}
                          isTab={isTabs}
                        >
                          {name}
                        </MenuItemLink>
                      );
                  }
                })()}
              </MenuItem>
            ))
        }
        </HorizontalScroll>
      </Menu>
    </Container>
  ) : null;
};

const Container = styled.nav<{ backgroundColor: string | undefined }>`
  background-color: ${({ backgroundColor, theme }): string => backgroundColor ?? theme.color.harleyDavidsonOrange};
`;

const Menu = styled.ul<{ usePadding: boolean }>`
  max-width: 1280px;
  margin: 0 auto;
  height: 35px;
  padding: 0 15px;

  @media ${Devices.desktopSmall} {
    padding: ${({ usePadding }): string => (usePadding ? '0 var(--nav-header-left-padding)' : '0')}
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  font-family: ${(props): string => props.theme.fontFamily.milan};
  height: 100%;
  vertical-align: top;
`;

const MenuItemLink = styled(LocalizedLink)<{ isActive?: boolean }>`
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid ${({ isActive }): string => (isActive ? 'white' : 'transparent')};

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

export default SecondLevelMenu;
